import { safeProp } from '@simplisafe/monda'
import { graphql } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React, { FC } from 'react'

import DynamicPackageMiniCart from '../components/DynamicPackageMiniCart'
import Page, { PageProps } from '../components/Page'
import { SiteWideMessagesContext } from '../contexts/siteWideMessagesContext'

export type PackageLineItem = {
  readonly isAutomaticallyIncluded: boolean
  readonly masterSku: string
  readonly quantity: number
}

const DynamicPackagePage: FC<PageProps> = ({
  data,
  location,
  pageContext,
  params
}: PageProps) => {
  const attributeHash = propOr<string, string>('', 'payload', params)

  return (
    <>
      <DynamicPackageMiniCart attributeHash={attributeHash} />
      <SiteWideMessagesContext.Provider value={safeProp('contentfulSiteWideMessages', data).orJust({})}>
        <Page
          data={data}
          location={location}
          pageContext={pageContext}
        />
      </SiteWideMessagesContext.Provider>
    </>
  )
}

// the #graphql tag is required for eslint and GraphQL for VSCode

export const query = graphql`#graphql
  query DynamicPackagePageQuery($id: String, $locale: String){
    contentfulSiteWideMessages(node_locale: {eq: $locale}) {
      ...siteWideMessages
    }
    contentfulPage(id: {eq: $id}) {
      ...pageFragment
    }
    # Looking for US Site Popup
    contentfulSmallTextSection(id: {eq: "f9321a44-b6d6-5867-9c83-b6a8cebc6e4a"}) {
      id
      description {
        json
      }
    }
  }
`

export default DynamicPackagePage
