import { IOMiniCart } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'

export type DynamicPackageMiniCartProps = {
    readonly attributeHash: string
}

// Fetches minicart data to be made available to downstream components on the Dynamic Package page
const DynamicPackageMiniCart: FC<DynamicPackageMiniCartProps> = (
  { attributeHash }: DynamicPackageMiniCartProps
) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(IOMiniCart(attributeHash))
  }, [ attributeHash, dispatch ])

  return null
}

export default DynamicPackageMiniCart
